<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Индивидуальные задания</div>
    <div class="section__lk-subtitle">Список заданий</div>
    <div class="section__lk-content-items mt-0" v-if="tasks">
      <template v-for="task in tasks">
        <template v-for="(content, index) in task.tutor_task_contents">
          <online-row-test :id="'test-start-' + content.id" :key="index" :result="content.result"  />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../services/api.service";
import OnlineRowTest from "@/components/profile/online/OnlineRowTest.vue";

export default {
  name: "TutorTask",
  components: {OnlineRowTest},
  data() {
    return {
      tasks: null
    }
  },
  async mounted() {
    let res = await ApiService.get('tutortasks');
    this.tasks = res.data
  }
}
</script>

<style scoped>

</style>